.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* body {
  font-family: sans-serif;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.custom-list {
  list-style-type: none;
  counter-reset: list-counter;
}
.custom-list li:before {
  content: counter(list-counter, lower-alpha) ". ";
  counter-increment: list-counter;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* Add this to your styles */


.slick-slide {
  padding: 20px; /* Set padding for each slide */
}

/* Custom styles for the dots (pagination) */
.slick-dots {
  bottom: 10px; /* Adjust the position of the dots */
}

/* Default dot style */
.slick-dots li button::before {
  content: '\2022'; /* Bullet character */
  color: #562626; /* Default color */
  font-size: 10px; /* Adjust the font size */
}

/* Active dot style */
.slick-dots li.slick-active button::before {
  color: yellow; /* Active color */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
